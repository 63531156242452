
import MaterialTable from 'material-table'
import { DropzoneArea } from 'material-ui-dropzone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import PartiesEditModal from '../../component/Modal/PartiesEditModal'
import { useDispatch, useSelector } from 'react-redux';
import { transactionDataAction } from '../../redux/actions/transactionDataAction'
import { entityGetAction } from '../../redux/actions/entityAction'
import { tableDataAtom, rowEditDataAtom, relatedPartyDetailsAtom, keyPartiesAtom, relationAtom, partiesDataAtom, buyersAtom, namesAtom, apiFetchedAtom } from '../transactions/Helpers/atoms'
import { useAtom } from 'jotai'
import { OptionalSpan } from './Helpers/OptionalTags'

const KeyParties = ({ hendelCancel, hendelNext, transactionType, getShippingCompany, getCounterParty, pricingHedgingStatus, getWarehouseCompany, warehouseStatus, getLender, getBorrower }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isView = location?.state[2]?.isView

    const [apiFetched, setApiFetched] = useAtom(apiFetchedAtom);
    const [editMode, setEditMode] = useState(false);
    const [relation, setRelation] = useAtom(relationAtom);
    const [party, setParty] = useState({ name: "", type: "" })
    const [showEditModal, setShowEditModal] = useState(false)
    const [tableData, setTableData] = useAtom(tableDataAtom)
    const [rowEditData, setRowEditData] = useAtom(rowEditDataAtom)
    const [editId, setEditId] = useState('')
    const [view, setView] = useState()
    const [error, setError] = useState({})
    const [names, setNames] = useAtom(namesAtom)
    const [buyers, setBuyer] = useAtom(buyersAtom)
    const [partiesData, setpartiesData] = useAtom(partiesDataAtom)
    const [keyParties, setkeyParties] = useAtom(keyPartiesAtom)
    const [relatedPartyDetails, setRelatedPartyDetails] = useAtom(relatedPartyDetailsAtom)

    const [borrower_Applicant, setBorrower_Applicant] = useState("")
    const [lenders, setLenders] = useState("")
    const [warehouseComp, setWarehouseComp] = useState("")
    const [warehouses, setWarehouses] = useState([])
    const [counterPart, setCounterPart] = useState("")
    const [shippingComp, setShippingComp] = useState("")
    const parties = [
        "Subsidiary",
        "Owners",
        "Associate",
        "None",
    ]

    const nameOption = useSelector(state => state.entityData.entity)
    const transactionData = useSelector((state) => state.transactionData.transactionData)
    const getTransactionByIdData = useSelector((state) => state.transactionData.getTransactionById)


    useEffect(() => {
        dispatch(entityGetAction('all'))
    }, []);

    useEffect(() => {
        if (getTransactionByIdData && getTransactionByIdData.data) {
            const keyPartiesData = getTransactionByIdData.data.keyParties?.[0]?.parties?.map((ele) => {
                return {
                    name: { label: ele.name?.details?.name ?? ele.name?.details?.givenName, value: ele.name?._id },
                    type: { label: ele.type?.roleName, value: ele.type?._id }
                };
            }) || [];

            setTableData(keyPartiesData);
            setApiFetched(true);
            setEditId(getTransactionByIdData.data.keyParties?.[0]?._id);
            setBorrower_Applicant(getTransactionByIdData.data?.details?.pricingDetails?.pricingCounterParty?.details.name);
            setLenders(getTransactionByIdData.data?.details?.lenders);
            setCounterPart(getTransactionByIdData.data?.details?.counterParty?.details.name);
            setShippingComp(getTransactionByIdData.data?.details?.shippingOptions?.shippingCompany?.details?.name);


            // if (getTransactionByIdData.data.keyParties?.[0]?.relatedParties) {
            //     setkeyParties(getTransactionByIdData.data.keyParties[0].relatedParties);
            //     setEditMode(true);
            // }
        }
    }, [getTransactionByIdData]);



    useEffect(() => {
        if (getTransactionByIdData.data?.keyParties[0].relatedParties != undefined && getTransactionByIdData.data?.keyParties[0].relatedParties.length > 0) {
            console.log('RELATEDPARTIES FROM API', relatedPartyDetails);
            setRelatedPartyDetails(getTransactionByIdData.data?.keyParties[0].relatedParties);
        }
    }, [getTransactionByIdData])

    // useEffect(() => {
    //     if (relatedPartyDetails) {
    //         console.log('RELATEDPARTIES useeffect 2', relatedPartyDetails);
    //     }
    // }, [relatedPartyDetails]);

    let temp = keyParties;


    const handleParties = (e, newValue, index, type) => {
        const updatedKeyParties = [...keyParties];
        const updatedRelatedPartyDetails = [...relatedPartyDetails];

        if (!updatedKeyParties[index]) {
            updatedKeyParties[index] = { party_relation: '', buyer: '', shipper: '', upload_evidence: '' };
        }

        if (!updatedRelatedPartyDetails[index]) {
            updatedRelatedPartyDetails[index] = { party_relation: '', buyer: '', shipper: '', upload_evidence: '' };
        }

        if (type === 'buyer') {
            if (updatedKeyParties[index].shipper !== newValue) {
                updatedKeyParties[index].buyer = newValue;
                updatedRelatedPartyDetails[index].buyer = newValue;
            } else {
                alert('Party 1 and Party 2 should not be identical');
            }
        } else {
            if (updatedKeyParties[index].buyer !== newValue) {
                updatedKeyParties[index].shipper = newValue;
                updatedRelatedPartyDetails[index].shipper = newValue;
            } else {
                alert('Party 1 and Party 2 should not be identical');
            }
        }

        setkeyParties(updatedKeyParties);
        setRelatedPartyDetails(updatedRelatedPartyDetails);
    };
    const handleRelatedParties = () => {
        setRelatedPartyDetails([...relatedPartyDetails, { buyer: '', shipper: '', party_relation: '', upload_evidence: '' }]);
    };

    const handleRelationChange = (e, index) => {
        const newRelation = e.target.value;
        const updatedRelatedPartyDetails = relatedPartyDetails.map((party, i) => (
            i === index ? { ...party, party_relation: newRelation } : party
        ));
        setRelatedPartyDetails(updatedRelatedPartyDetails);
    };

    const handleChangeFile = (file, index) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const updatedKeyParties = [...keyParties];
                updatedKeyParties[index].upload_evidence = [{ type: 'img', name: file.name, file: reader.result }];
                console.log('Updated Key Parties:', updatedKeyParties);
                setkeyParties(updatedKeyParties);
            };
        }
    };



    useEffect(() => {
        if (nameOption?.data) {
            // console.log(nameOption?.data);
            var temp_names = [];
            nameOption?.data.forEach((element, index) => {
                element.details.name = element.details?.name != null ? element.details?.name : element.details?.givenName;
                temp_names.push(element)
            });
            console.log("GET RELATED PARTIES DATA-----", temp_names);
            setNames(temp_names)
        }
    }, [nameOption])

    const partiesEditData = (data, id) => {
        if (id !== undefined) {
            setTableData(
                tableData.map((ele, i) => {
                    if (id === i) {
                        return data
                    } else {
                        return ele
                    }
                })
            )
            setRowEditData('')
        } else {
            setTableData([...tableData, data])
        }
    }
    const validation = () => {
        let flag = false
        let error = {}

        // if (!relatedPartyDetails.buyer) {
        //     flag = true
        //     error.buyer = 'Please select a party'
        // }
        // if (!relatedPartyDetails.shipper) {
        //     flag = true
        //     error.shipper = 'Please select a party'
        // }
        // if (!relatedPartyDetails.party_relation) {
        //     flag = true
        //     error.party_relation = 'Please select a relation'
        // }
        // if (relatedPartyDetails.length < 1) {
        //     flag = true
        //     error.relatedPartyDetails = 'Please enter document remittance'
        // }
        setError(error)
        return flag
    }


    const next = () => {
        // if (validation()) {
        //     return
        // }
        let relatedParties = keyParties;

        let body = {
            ...transactionData,
            keyParties: {
                _id: editId,
                keyParties: tableData.map((ele) => {
                    return {
                        type: ele.type,
                        name: ele.name
                    }
                }),
                relatedParties: relatedParties,
            },
            type: transactionType
        }

        dispatch(transactionDataAction(body))
        hendelNext()
    }
    console.log('TAbLE dATa', tableData)
    useEffect(() => {
        let buyer_arr = [];
        let warehouses = [];
        if (names) {
            names.forEach(element => {
                element.roles.forEach(roleDetail => {
                    if (roleDetail.roleId?.roleName == "Buyer") {
                        var temp = {
                            label: element.details.name != null ? element.details.name : element.details.givenName,
                            value: element.details.name != null ? element.details.name : element.details.givenName,
                            prefix: ''
                        };
                        buyer_arr.push(temp);
                        element.warehouses.forEach(warehose => {
                            warehouses.push(warehose);
                        })
                    }
                });

            });
        }
        setBuyer(buyer_arr);
        setWarehouses(warehouses);
    }, [names])


    const tdata = useMemo(() => tableData.map(item => item?.name?.label), [tableData]);
    const warehouseCo = useMemo(() => getWarehouseCompany?.warehouses?.[0]?.warehouseCompany?.label, [getWarehouseCompany]);
    const AddUpParties = useCallback(() => {
        const storeData = [
            getBorrower,
            getLender,
            getShippingCompany,
            warehouseCo,
            getCounterParty,
            ...tdata,
        ].filter(item => item); // Filter out any undefined or null values

        setpartiesData(storeData);
    }, [getBorrower, getLender, getShippingCompany, warehouseCo, getCounterParty, tdata]);
    console.log('Check for lender', getLender)

    console.log('warehouse company', getWarehouseCompany?.warehouses?.[0]?.warehouseCompany?.label)
    console.log('Shipping company', getShippingCompany?.shippingCompany?.label)
    // console.log('Hedging Counterparty', getCounterParty.pricingCounterParty?.details?.name)
    useEffect(() => {
        AddUpParties()
    }, [AddUpParties])

    return (
        <>
            <div className='product'>
                <div className='mb-3 d-flex justify-content-between align-items-center'>
                    <h6 className="fs-5 fw-bold title-admin text-muted">PARTIES INVOLVED</h6>
                </div>
                <Form>
                    <Row>
                        <Form.Group as={Col} lg={4} md={6} m={12} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label className='text-muted'> Borrower/Applicant </Form.Label>

                            <Form.Control className='text-muted no-border' type="text"
                                name='borrower_Applicant'
                                value={getBorrower}
                                disabled={true} />

                        </Form.Group>

                        <Form.Group as={Col} lg={4} md={6} m={12} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label className='text-muted'>Lender</Form.Label>

                            <Form.Control className='text-muted no-border'
                                name='lenders'
                                value={getLender}
                                disabled={true} />

                        </Form.Group>

                        <Form.Group as={Col} lg={4} md={6} m={12} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label className='text-muted'>Shipping Company</Form.Label>

                            <Form.Control className='text-muted no-border'
                                name='lenders'
                                value={getShippingCompany}
                                disabled={true} />

                        </Form.Group>
                    </Row>
                    <Row>
                        {warehouseStatus &&
                            <Form.Group as={Col} lg={4} md={6} m={12} className="mb-3" controlId="formHorizontalEmail">
                                <Form.Label className='text-muted'>Warehouse Company</Form.Label>

                                <Form.Control className='text-muted no-border' name='warehouse company'
                                    value={warehouseCo}
                                    disabled={true} />

                            </Form.Group>
                        }

                        {pricingHedgingStatus &&
                            <Form.Group as={Col} lg={4} md={6} sm={12} className="mb-3" controlId="formHorizontalEmail">
                                <Form.Label className='text-muted'>Hedging Counterparty</Form.Label>

                                <Form.Control className='text-muted no-border'
                                    name='Counterparty'
                                    value={getCounterParty}
                                    disabled={true} />

                            </Form.Group>}
                    </Row>



                </Form>


                <div className='mb-2 pt-4 d-flex justify-content-between align-items-center'>
                    <h6 className='fs-5 fw-bold title-admin' >KEY PARTIES</h6>

                    <Button onClick={() => { setShowEditModal(!showEditModal) }} class={`btn d-inline-flex btn-md btn-light border-base mx-1 me-1`} disabled={isView}>
                        <span class=' pe-2'><i class="bi bi-plus pe-1 "></i></span>
                        <span className='fw-bold'>Add</span>
                    </Button>
                </div>
                <MaterialTable
                    title=""
                    columns={[

                        { title: 'Party', field: 'name.label' },
                        { title: 'Role', field: 'type.label' },

                    ]}
                    data={tableData}

                    actions={isView ? [{
                        icon: 'preview',
                        tooltip: 'View Product',
                        onClick: (event, rowData) => { setShowEditModal(!showEditModal); setRowEditData(rowData); setView(isView) }
                    }] : [
                        {
                            icon: 'edit',
                            tooltip: 'Edit Product',
                            onClick: (event, rowData) => { setRowEditData(rowData); setShowEditModal(!showEditModal); console.log('rowData ==', rowData) }
                        },
                        {
                            icon: 'preview',
                            tooltip: 'View Product',
                            onClick: (event, rowData) => { setShowEditModal(!showEditModal); setRowEditData(rowData) }
                        }
                    ]}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        sorting: false,
                        pageSize: 10,
                        search: false,
                        emptyRowsWhenPaging: false,
                    }}
                />
            </div>
            <div className='add-edit-product parties_main mb-4'>
                <div className='p-4 mb-3 pb-4'>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                        <h6 className="fs-5 fw-bold title-admin">RELATED PARTIES</h6>
                        <Button onClick={handleRelatedParties} class='btn d-inline-flex btn-md btn-light border-base mx-1 me-1' disabled={isView}>
                            <span class=' pe-2'><i class="bi bi-plus pe-1 "></i></span>
                            <span className='fw-bold'>Add</span>
                        </Button>
                    </div>

                    <>
                        <Form>
                            {relatedPartyDetails.map((party, index) => (
                                <Row key={index}>
                                    <Form.Group as={Col} lg={3}>
                                        <Form.Label>Party 1 <OptionalSpan /></Form.Label>
                                        <Form.Select
                                            onChange={(e) => handleParties(e, e.target.value, index, 'buyer')}
                                            value={party.buyer}
                                            className='no-border'
                                            disabled={isView}
                                        >
                                            <option value="">Choose...</option>
                                            {partiesData.map((item, i) => (
                                                <option key={i} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} lg={3}>
                                        <Form.Label>Party 2 <OptionalSpan /></Form.Label>
                                        <Form.Select
                                            onChange={(e) => handleParties(e, e.target.value, index, 'shipper')}
                                            value={party.shipper}
                                            className='no-border'
                                            disabled={isView}
                                        >
                                            <option value="">Choose...</option>
                                            {partiesData.map((item, i) => (
                                                <option key={i} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <Form.Label>Relation <OptionalSpan /></Form.Label>
                                        <Form.Select
                                            onChange={(e) => handleRelationChange(e, index)}
                                            value={party.party_relation || 'Choose...'}
                                            disabled={isView}
                                        >
                                            <option disabled>Choose...</option>
                                            {parties.map((item, i) => (
                                                <option key={i} value={item}> {item} </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Col lg={2}>
                                        <div className='drag-and-drop'>
                                            <DropzoneArea
                                                filesLimit={1}
                                                showPreviews={true}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview
                                                previewGridProps={{ container: { spacing: 1 } }}
                                                dropzoneText='Upload Evidence'
                                                onChange={(files) => handleChangeFile(files[0], index)}
                                                disabled={!party.buyer || !party.shipper || !party.party_relation}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Form>
                    </>
                </div>
            </div>
            <div className='footer_'>
                <button onClick={() => { transactionType === "Export" ? hendelCancel() : navigate('/transactions') }} className="footer_cancel_btn">Back</button>
                <button onClick={() => { tableData.length > 0 && next() }} className='footer_next_btn'> Next</button>
            </div>

            {showEditModal && <PartiesEditModal show={showEditModal} onHide={() => { setShowEditModal(false); setRowEditData('') }} getModalData={(e, id) => partiesEditData(e, id)} editData={rowEditData} isView={view} />}
        </>
    )
}

export default KeyParties