export const IS_LOADING = "IS_LOADING";

export const USER_DATA = "USER_DATA";
export const USER_DATA_ERR = "USER_DATA_ERR";
export const USER_NOTIFICATION_TOGGLE = "USER_NOTIFICATION_TOGGLE";

export const CHANGE_LOGIN_STATE = "CHANGE_LOGIN_STATE";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";

export const CHAT_DATA = "CHAT_DATA";
export const MESSAGE_STATE = "MESSAGE_STATE";
export const CHAT_STATE = "CHAT_STATE";
export const SET_OTHER_USER = "SET_OTHER_USER";
export const CHAT_ID = "CHAT_ID";

export const GET_NOTIFICATION = "GET_NOTIFICATION";

export const REGISTER = "REGISTER";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const COUNTRIES = "COUNTRIES"
export const COUNTRIES_LOADING = "COUNTRIES_LOADING"
export const COUNTRIES_ERROR = "COUNTRIES_ERROR"

export const PRODUCT_GET_BY_ID = "PRODUCT_GET_BY_ID"
export const PRODUCT_GET_BY_ID_LOADING = "PRODUCT_GET_BY_ID_LOADING"
export const PRODUCT_GET_BY_ID_ERROR = "PRODUCT_GET_BY_ID_ERROR"

export const PRODUCT = "PRODUCT"
export const PRODUCT_LOADING = "PRODUCT_LOADING"
export const PRODUCT_ERROR = "PRODUCT_ERROR"

export const PRODUCTADD = "PRODUCTADD"
export const PRODUCTADD_LOADING = "PRODUCTADD_LOADING"
export const PRODUCTADD_ERROR = "PRODUCTADD_ERROR"

export const PRODUCT_UPDATE = "PRODUCT_UPDATE"
export const PRODUCT_UPDATE_LOADING = "PRODUCT_UPDATE_LOADING"
export const PRODUCT_UPDATE_ERROR = "PRODUCT_UPDATE_ERROR"

export const GET_USER_DATA = "GET_USER_DATA"
export const GET_USER_DATA_LOADING = "GET_USER_DATA_LOADING"
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR"

export const USER_GET_BY_ID = "USER_GET_BY_ID"
export const USER_GET_BY_ID_LOADING = "USER_GET_BY_ID_LOADING"
export const USER_GET_BY_ID_ERROR = "USER_GET_BY_ID_ERROR"

export const USER_UPDATE = "USER_UPDATE"
export const USER_UPDATE_LOADING = "USER_UPDATE_LOADING"
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR"

export const RATINGAGENCIES = "RATINGAGENCIES"
export const RATINGAGENCIES_LOADING = "RATINGAGENCIES_LOADING"
export const RATINGAGENCIES_ERROR = "RATINGAGENCIES_ERROR"

export const RATINGAGENCIES_ADD = "RATINGAGENCIES_ADD"
export const RATINGAGENCIES_ADD_LOADING = "RATINGAGENCIES_ADD_LOADING"
export const RATINGAGENCIES_ADD_ERROR = "RATINGAGENCIES_ADD_ERROR"

export const RATINGAGENCIES_GET_BY_ID = "RATINGAGENCIES_GET_BY_ID"
export const RATINGAGENCIES_GET_BY_ID_LOADING = "RATINGAGENCIES_GET_BY_ID_LOADING"
export const RATINGAGENCIES_GET_BY_ID_ERROR = "RATINGAGENCIES_GET_BY_ID_ERROR"

export const RATINGAGENCY_UPDATE = "RATINGAGENCY_UPDATE"
export const RATINGAGENCY_UPDATE_LOADING = "RATINGAGENCY_UPDATE_LOADING"
export const RATINGAGENCY_UPDATE_ERROR = "RATINGAGENCY_UPDATE_ERROR"

export const ENTITY_ADD = "ENTITY_ADD"
export const ENTITY_ADD_LOADING = "ENTITY_ADD_LOADING"
export const ENTITY_ADD_ERROR = "ENTITY_ADD_ERROR"

export const EDIT_ENTITY = "EDIT_ENTITY"
export const EDIT_ENTITY_LOADING = "EDIT_ENTITY_LOADING"
export const EDIT_ENTITY_ERROR = "EDIT_ENTITY_ERROR"

export const ENTITY = "ENTITY"
export const ENTITY_LOADING = "ENTITY_LOADING"
export const ENTITY_ERROR = "ENTITY_ERROR"

export const ENTITY_GET = "ENTITY_GET"
export const ENTITY_GET_LOADING = "ENTITY_GET_LOADING"
export const ENTITY_GET_ERROR = "ENTITY_GET_ERROR"

export const ENTITYROLE = "ENTITYROLE"
export const ENTITYROLE_LOADING = "ENTITYROLE_LOADING"
export const ENTITYROLE_ERROR = "ENTITYROLE_ERROR"

export const ENTITYROLE_ADD = "ENTITYROLE_ADD"
export const ENTITYROLE_ADD_LOADING = "ENTITYROLE_ADD_LOADING"
export const ENTITYROLE_ADD_ERROR = "ENTITYROLE_ADD_ERROR"

export const ENTITYROLE_UPDATE = "ENTITYROLE_UPDATE"
export const ENTITYROLE_UPDATE_LOADING = "ENTITYROLE_UPDATE_LOADING"
export const ENTITYROLE_UPDATE_ERROR = "ENTITYROLE_UPDATE_ERROR"

export const ENTITYROLE_DELETE = "ENTITYROLE_DELETE"
export const ENTITYROLE_DELETE_LOADING = "ENTITYROLE_DELETE_LOADING"
export const ENTITYROLE_DELETE_ERROR = "ENTITYROLE_DELETE_ERROR"

export const ENTITY_GET_BY_ID = "ENTITY_GET_BY_ID"
export const ENTITY_GET_BY_ID_LOADING = "ENTITY_GET_BY_ID_LOADING"
export const ENTITY_GET_BY_ID_ERROR = "ENTITY_GET_BY_ID_ERROR"

export const SECTOR = "SECTOR"
export const SECTOR_LOADING = "SECTOR_LOADING"
export const SECTOR_ERROR = "SECTOR_ERROR"

export const COMPANY_DATA = "COMPANY_DATA"

export const GET_WAREHOUSE = "GET_WAREHOUSE"
export const GET_WAREHOUSE_LOADING = "GET_WAREHOUSE_LOADING"
export const GET_WAREHOUSE_ERROR = "GET_WAREHOUSE_ERROR"

export const TRANSACTION_DATA = "TRANSACTION_DATA"

export const ADD_TRANSACTION = "ADD_TRANSACTION"
export const ADD_TRANSACTION_LOADING = "ADD_TRANSACTION_LOADING"
export const ADD_TRANSACTION_ERROR = "ADD_TRANSACTION_ERROR"

export const DETAILS = "DETAILS"
export const DETAILS_LOADING = "DETAILS_LOADING"
export const DETAILS_ERROR = "DETAILS_ERROR"

export const KEYPARTIES = "KEYPARTIES"
export const KEYPARTIES_LOADING = "KEYPARTIES_LOADING"
export const KEYPARTIES_ERROR = "KEYPARTIES_ERROR"

export const DOCUMENTFLOW = "DOCUMENTFLOW"
export const DOCUMENTFLOW_LOADING = "DOCUMENTFLOW_LOADING"
export const DOCUMENTFLOW_ERROR = "DOCUMENTFLOW_ERROR"


export const FUNDFLOW = "FUNDFLOW"
export const FUNDFLOW_LOADING = "FUNDFLOW_LOADING"
export const FUNDFLOW_ERROR = "FUNDFLOW_ERROR"

export const FACILITY = "FACILITY"
export const FACILITY_LOADING = "FACILITY_LOADING"
export const FACILITY_ERROR = "FACILITY_ERROR"

export const GET_ALL_TRANSACTION = "GET_ALL_TRANSACTION"
export const GET_ALL_TRANSACTION_LOADING = "GET_ALL_TRANSACTION_LOADING"
export const GET_ALL_TRANSACTION_ERROR = "GET_ALL_TRANSACTION_ERROR"

export const GET_TRANSACTION_BY_ID = "GET_TRANSACTION_BY_ID"
export const GET_TRANSACTION_BY_ID_LOADING = "GET_TRANSACTION_BY_ID_LOADING"
export const GET_TRANSACTION_BY_ID_ERROR = "GET_TRANSACTION_BY_ID_ERROR"

export const EDIT_TRANSACTION = "EDIT_TRANSACTION"
export const EDIT_TRANSACTION_LOADING = "EDIT_TRANSACTION_LOADING"
export const EDIT_TRANSACTION_ERROR = "EDIT_TRANSACTION_ERROR"

export const RISK_ASSESSMENT_DATA = "RISK_ASSESSMENT_DATA"

export const ADD_RISK_ASSESSMENT = "ADD_RISK_ASSESSMENT"
export const ADD_RISK_ASSESSMENT_LOADING = "ADD_RISK_ASSESSMENT_LOADING"
export const ADD_RISK_ASSESSMENT_ERROR = "ADD_RISK_ASSESSMENT_ERROR"

export const GET_RISK_ASSESSMENT = "GET_RISK_ASSESSMENT"
export const GET_RISK_ASSESSMENT_LOADING = "GET_RISK_ASSESSMENT_LOADING"
export const GET_RISK_ASSESSMENT_ERROR = "GET_RISK_ASSESSMENT_ERROR"

export const PORT = "PORT"
export const PORT_LOADING = "PORT_LOADING"
export const PORT_ERROR = "PORT_ERROR"

export const AIR_PORT = "AIR_PORT"
export const AIR_PORT_LOADING = "AIR_PORT_LOADING"
export const AIR_PORT_ERROR = "AIR_PORT_ERROR"

export const TERM_SHEET = "TERM_SHEET"
export const TERM_SHEET_LOADING = "TERM_SHEET_LOADING"
export const TERM_SHEET_ERROR = "TERM_SHEET_ERROR"
